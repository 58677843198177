<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Equipment</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="equipment.equipmentName"
                label="Equipment Name"
                hint="Name for this End Use"
                persistent-hint
                rules="required"
                ref="nameText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <validated-text-field
                v-model="equipment.equipmentFriendlyName"
                label="Equipment Display Name"
                hint="Friendly Name for this End Use"
                persistent-hint
                rules="required"
                ref="friendlyNameText"
              ></validated-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <validated-select-field
                :items="equipmentTypes"
                item-value="equipmentTypeId"
                item-text="equipmentTypeName"
                rules="required"
                v-model="equipment.equipmentTypeId"
                label="Equipment Type"
                hint="What type of equipment is this?"
                persistent-hint
                v-on:input="handleSetEquipmentType($event)"
              ></validated-select-field>
            </v-col>

            <v-col sm="6">
              <site-location-field
                v-if="finishedLoading"
                :siteId="siteId"
                :selectedSiteLocationId="equipment.siteLocationId"
                @sitelocationselected="siteLocationSelected"
                ref="siteLocation"
              ></site-location-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidEquipment">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row justify="center">
          <stepper-validate 
          :invalid="invalid"
          :equipmentName="equipmentName"
          :friendlyName="equipmentFriendlyName"
          :siteLocation="selectedSiteLocation"
          @dismissdialog = "dismissDialog"
          @fixequipment = "fixEquipmentErrors"
          ref="stepper"></stepper-validate>
        </v-row>
      </v-form></validation-observer
    ></v-container
  >
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import StepperValidate from "./StepperValidate.vue"
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "stepper-validate": StepperValidate
  },

  data() {
    return {
      valid: true,
      equipment: {
        siteLocationId: null,
      },

      siteId: null,
      equipmentName: null,
      equipmentFriendlyName: null,
      equipmentTypes: [],
      selectedEquipmentType: null,
      siteLocations: [],
      selectedSiteLocation: null,
      isValidEquipment: true,
      finishedLoading: false
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    this.equipmentId = this.$route.params.equipmentId;
    this.equipment = await api.getEquipmentById(this.equipmentId);
    try {
      await this.getLookups(this.siteId);
    } catch (error) {
      console.error(error);
    }
    this.finishedLoading = true;
  },

  methods: {

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.$refs.stepper.validateMeter();
    },

    async getLookups() {
      this.equipmentTypes = await api.getEquipmentTypes();
    },

    async handleSetEquipmentType(equipmentTypeId) {
      console.log(equipmentTypeId);
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    fixEquipmentErrors(item) {
      switch (item) {
        case 1:
          this.$nextTick().then(() => {
            this.$refs.nameText.focus();
          });
          break;
        case 3:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        default:
          break;
      }
    },

    async dismissDialog(isValid) {
      if (isValid) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          this.equipment.siteLocationId = this.selectedSiteLocation?.id;
          await api.updateEquipment(this.equipment);
          this.$router.push({ name: "EquipmentIndex" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    handleCancelCrud() {
      this.$router.push({name: "EquipmentIndex"});
    }

  },
};
</script>