const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSiteLocationsForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/sitelocation/SiteLocations/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getEquipmentForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/equipment/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getEquipmentById = async (equipmentId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/equipment/edit/${equipmentId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const getEquipmentTypes = async () => {
    let result = null
    try {
        result = await window.axios.get(`/v1/equipmenttype/index`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const createEquipment = async (equipment) => {
    try {
        await window.axios.post('/v1/equipment/create', equipment)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateEquipment = async (equipment) => {
    try {
        await window.axios.put('/v1/equipment/update', equipment)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const deleteEquipment = async (equipmentId) => {
    let result = null
    console.log(equipmentId);

    try {
        result = await window.axios.delete(`/v1/equipment/delete/${equipmentId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

export default {
    getSites,
    getEquipmentTypes,
    getEquipmentForSite,
    getEquipmentById,
    createEquipment,
    updateEquipment,
    deleteEquipment,
    getSiteLocationsForSite
}